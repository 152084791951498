<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openProcessWorkOrder"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">WORK ORDERS</h1>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.createdBy.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.weight.toFixed(2) }} kg</p>
                </div>
            </template>
            <template v-slot:[`item.quality`]="{ item }">
                <div>
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-col>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #2b81d6;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOPending(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #2fbf56;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOReleased(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #ffa41d;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOReprocess(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #FF2610;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWORejected(item) }}
                                    </p>
                                </v-btn>
                            </v-col>
                        </template>
                        <span>
                            Pending :
                            {{ calculateWOPending(item) || 0 }}
                            <br />
                            Released :
                            {{ calculateWOReleased(item) }} <br />
                            Reprocess :
                            {{ calculateWOReprocess(item) }} <br />
                            Rejected :
                            {{ calculateWORejected(item) }} <br />
                        </span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS WORK ORDER-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'RejectedQA',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {
        ProcessWorkOrder: () =>
            import('@/components/WorkOrders/ProcessWorkOrder.vue'),
    },
    data: () => ({
        selectedOrder: undefined,
        processWorkOrder: false,
        workOrder: false,
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
                width: 240,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REJECTED QTY',
                value: 'qaRejected',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUALITY',
                value: 'quality',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        enableOptions: true,
        resourceId: undefined,
        settingProcesses: undefined,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        user: {},
    }),
    computed: {
        ...mapState(['notificationResource']),
        workOrdersFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.workOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            const pendingWOs = this.workOrders.filter(wo => wo.qaRejected > 0)
            return pendingWOs
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            const {
                data: { users },
            } = await API.getLiteUsers()
            const projects = await API.getLiteProjects({ all: true })
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .where('status', '==', 'approved')
            if (!user.permissions.includes('readAllWO')) {
                query = query.where('createdBy', '==', user.id)
            }
            this.listener = await query.onSnapshot(async docSnapshot => {
                docSnapshot.docChanges().forEach(async change => {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    const userIndex = users.findIndex(
                        u => u.id == workOrder.createdBy
                    )
                    if (userIndex >= 0) {
                        workOrder.createdBy = users[userIndex]
                    } else {
                        workOrder.createdBy = { id: workOrder.createdBy }
                        workOrder.createdBy.name = ''
                    }
                    if (change.type === 'added') {
                        const projectIndex = projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            projects.push(workOrder.project)
                        }
                        this.workOrders.splice(0, 0, workOrder)
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                projects.push(workOrder.project)
                            }
                            this.workOrders.splice(index, 1, workOrder)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }

                    this.setProcessesInfo(workOrder)
                })
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        openProcessWorkOrder(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.processWorkOrder = true
        },
        closeProcessWorkOrder() {
            this.processWorkOrder = false
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        sort() {
            this.workOrders.sort(
                (a, b) => (b.qaRejected || 0) - (a.qaRejected || 0)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.workOrders.find(
                    w => w.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessWorkOrder(resource)
                }
            }
        },
        setProcessesInfo(wo) {
            wo.qaRejected = this.calculateWORejected(wo)
            if (this.selectedOrder && this.selectedOrder.id == wo.id) {
                this.selectedOrder = _.cloneDeep(wo)
            }
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                const lastProductionProcess =
                    item.processes[item.processes.length - 1]
                if (item.production) {
                    const production = item.production.filter(
                        entry => entry.process.id == lastProductionProcess
                    )
                    if (production.length > 0) {
                        pendingQty = production.reduce(
                            (accumulator, entry) => accumulator + entry.qty,
                            0
                        )
                    }
                }
                if (item.quality) {
                    const rejected = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    const released = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    pendingQty -= Number(released || 0) + Number(rejected || 0)
                }
                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    released += Number(value || 0)
                }
                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    rejected += Number(value || 0)
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOPending(wo) {
            try {
                let totalPending = 0
                wo.items.forEach(item => {
                    let pendingQty = 0
                    const lastProductionProcess =
                        item.processes[item.processes.length - 1]
                    if (item.production) {
                        const production = item.production.filter(
                            entry => entry.process.id == lastProductionProcess
                        )
                        if (production.length > 0) {
                            pendingQty = production.reduce(
                                (accumulator, entry) => accumulator + entry.qty,
                                0
                            )
                        }
                    }
                    if (item.quality) {
                        const rejected = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        const released = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        pendingQty -=
                            Number(released || 0) + Number(rejected || 0)
                    }
                    totalPending += pendingQty
                })

                return totalPending
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOReleased(wo) {
            try {
                let totalReleased = 0
                wo.items.forEach(item => {
                    let released = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        released += Number(value || 0)
                    }
                    totalReleased += released
                })
                return totalReleased
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOReprocess(wo) {
            try {
                let totalReprocess = 0
                wo.items.forEach(item => {
                    let reprocess = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.reprocessQty || 0),
                            0
                        )
                        reprocess += Number(value || 0)
                    }
                    totalReprocess += reprocess
                })

                return totalReprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWORejected(wo) {
            try {
                let totalRejected = 0
                wo.items.forEach(item => {
                    let rejected = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        rejected += Number(value || 0)
                    }
                    totalRejected += rejected
                })

                return totalRejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
